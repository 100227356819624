import { call, put, takeLatest } from 'redux-saga/effects';
import ServiceFactory from 'lib/serviceFactory';
import * as types from './actionTypes';
import * as constants from './constants';
export function* fetchPosts({ payload: { filter, options } }) {
	try {
		const result = yield call(ServiceFactory.call, constants.FETCH_POSTS_URL, {
			filter,
			options,
		});
		yield put({
			type: types.FETCH_POSTS_COMPLETE,
			payload: {
				posts: result,
			},
		});
	} catch (error) {
		yield put({
			type: types.FETCH_POSTS_ERROR,
			payload: {
				error,
			},
		});
	}
}
export function* fetchPostDetail({ payload: { id } }) {
	try {
		const result = yield call(ServiceFactory.call, constants.FETCH_POST_DETAIL_URL, {
			id,
		});
		yield put({
			type: types.FETCH_POST_DETAIL_COMPLETE,
			payload: {
				postDetail: result,
			},
		});
	} catch (error) {
		yield put({
			type: types.FETCH_POST_DETAIL_ERROR,
			payload: {
				error,
			},
		});
	}
}
export function* addPost({ payload: { post } }) {
	try {
		yield call(ServiceFactory.call, constants.ADD_POST_URL, {
			post,
		});
		yield put({
			type: types.ADD_POST_COMPLETE,
		});
	} catch (error) {
		yield put({
			type: types.ADD_POST_ERROR,
			payload: {
				error,
			},
		});
	}
}
export function* updatePost({ payload: { id, post } }) {
	try {
		yield call(ServiceFactory.call, constants.UPDATE_POST_URL, {
			id,
			post,
		});
		yield put({
			type: types.UPDATE_POST_COMPLETE,
		});
	} catch (error) {
		yield put({
			type: types.UPDATE_POST_ERROR,
			payload: {
				error,
			},
		});
	}
}
export function* removePost({ payload: { id } }) {
	try {
		yield call(ServiceFactory.call, constants.REMOVE_POST_URL, {
			id,
		});
		yield put({
			type: types.REMOVE_POST_COMPLETE,
		});
	} catch (error) {
		yield put({
			type: types.REMOVE_POST_ERROR,
			payload: {
				error,
			},
		});
	}
}

export function* watchSagas() {
	yield takeLatest(types.FETCH_POSTS_BEGIN, fetchPosts);
	yield takeLatest(types.FETCH_POST_DETAIL_BEGIN, fetchPostDetail);
	yield takeLatest(types.ADD_POST_BEGIN, addPost);
	yield takeLatest(types.UPDATE_POST_BEGIN, updatePost);
	yield takeLatest(types.REMOVE_POST_BEGIN, removePost);
}
export default [watchSagas()];
