import { all } from 'redux-saga/effects';

import activities from 'app/activities';
import ad_campaigns from 'app/ad/ad-campaigns';
import ad_sellers from 'app/ad/sellers';
import withdrawals from 'app/ad/withdrawals';
import admins from 'app/admins';
import bills from 'app/bills';
import campaigns from 'app/campaigns';
import channels from 'app/channels';
import contracts from 'app/contracts';
import customers from 'app/customers';
import dashboard from 'app/dashboard';
import issues from 'app/issues';
import messages from 'app/messages';
import modules from 'app/modules';
import notes from 'app/notes';
import notifications from 'app/notifications';
import orders from 'app/orders';
import payments from 'app/payments';
import products from 'app/products';
import settlements from 'app/settlements';
import shops from 'app/shops';
import transactions from 'app/transactions';
import users from 'app/users';
import core from 'core';
import posts from 'app/posts';
//import invoices from 'app/invoices';

export default function* appSaga() {
	return yield all([
		...core.sagas,
		...bills.sagas,
		...shops.sagas,
		...users.sagas,
		...orders.sagas,
		...admins.sagas,
		...issues.sagas,
		...messages.sagas,
		...channels.sagas,
		...products.sagas,
		...dashboard.sagas,
		...customers.sagas,
		...activities.sagas,
		...settlements.sagas,
		...notifications.sagas,
		...transactions.sagas,
		...payments.sagas,
		...contracts.sagas,
		...modules.sagas,
		...campaigns.sagas,
		...ad_sellers.sagas,
		...withdrawals.sagas,
		...ad_campaigns.sagas,
		...notes.sagas,
		...posts.sagas,
		//...invoices.sagas,
	]);
}
