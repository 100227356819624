import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import core from 'core';
import classNames from 'classnames';
const Pagination = core.components.Pagination;

function Table({ rows = [], limit, currentPage, totalCount, isRemoving, remove, currentUser }) {
	return (
		<div className='table-responsive'>
			<table className='table table-bordered table-centered mb-0'>
				<thead className='thead-light'>
					<tr>
						<th style={{ width: 20 }}>#</th>
						<th>Гарчиг</th>
						<th>Зураг</th>
						<th>Мэдээний төрөл</th>
						<th style={{ width: 150 }}>Төлөв</th>
						<th style={{ width: 125 }} />
					</tr>
				</thead>
				<tbody>
					{rows.map((row, index) => (
						<tr key={`tr_${row.id}`}>
							<td>{index + 1}</td>
							<td>{row.title}</td>
							<td>
								<img
									src={row.image}
									style={{
										width: '50px',
										height: '50px',
										aspectRatio: 1 / 1,
										objectFit: 'cover',
									}}
								/>
							</td>
							<td>{row.blog_type}</td>
							<td>
								<span
									className={classNames('badge p-1', {
										'badge-success': row.status === 'enabled',
										'badge-danger': row.status === 'disabled',
									})}
								>
									{StatusMap[row.status]}
								</span>
							</td>
							<td style={{ width: 125 }}>
								<Link to={`/posts/edit/${row.id}`} className='action-icon'>
									{' '}
									<i className='mdi mdi-square-edit-outline' />
								</Link>
								{currentUser.id !== row.id && (
									<a
										href='#'
										className='action-icon'
										onClick={(event) => {
											event.preventDefault();
											if (window.confirm('Та итгэлтэй байна уу?')) {
												if (!isRemoving) {
													remove(row.id);
												}
											}
										}}
									>
										<i className='mdi mdi-delete' />
									</a>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

const StatusMap = {
	enabled: 'Идэвхтэй',
	disabled: 'Идэвхгүй',
};

export default Table;
