export const NAME = 'posts';
export const FETCH_POSTS = 'fetch_posts';
export const FETCH_POSTS_URL = 'posts/fetchPosts';
export const FETCH_POST_DETAIL = 'fetch_post_detail';
export const FETCH_POST_DETAIL_URL = 'posts/fetchPostDetail';
export const ADD_POST = 'add_post';
export const ADD_POST_URL = 'posts/addPost';
export const UPDATE_POST = 'update_post';
export const UPDATE_POST_URL = 'posts/updatePost';
export const REMOVE_POST = 'remove_post';
export const REMOVE_POST_URL = 'posts/removePost';
