import React from "react";
import moment from "moment";

import { reduxForm, Field } from "redux-form";

import core from "core";

function OptionsTable({
  handleSubmit,
  removeOption,
  isAddingOption,
  options = [],
}) {
  return (
    <div id="shop-options" className="tab-pane fade" role="tabpanel">
      <div className="row mb-2">
        <div className="col-lg-12">
          <form
            onSubmit={handleSubmit}
            className="form-inline shop-filter-form"
          >
            <div style={{ width: 250 }} className="form-group mr-2">
              <Field
                required
                name="key"
                disabled={isAddingOption}
                placeholder="Төрөл сонгох..."
                options={[
                  { label: "go_delivery_company_id", value: "go_delivery_company_id" },
                  { label: "booklo_sender_id", value: "booklo_sender_id" },
                  { label: "toki_merchant_id", value: "toki_merchant_id" },
                  { label: "toki_token", value: "toki_token" },
                  { label: "simple_client_id", value: "simple_client_id" },
                  {
                    label: "simple_client_secret",
                    value: "simple_client_secret",
                  },
                  { label: "qpay_merchant_id", value: "qpay_merchant_id" },
                  { label: "qpay_template_id", value: "qpay_template_id" },
                  { label: "monpay_client_id", value: "monpay_client_id" },
                  {
                    label: "monpay_client_secret",
                    value: "monpay_client_secret",
                  },
                  {
                    label: "monpay_branch_username",
                    value: "monpay_branch_username",
                  },
                  { label: "golomt_hmac_key", value: "golomt_hmac_key" },
                  {
                    label: "socialpay_payment_token",
                    value: "socialpay_payment_token",
                  },
                  { label: "card_payment_token", value: "card_payment_token" },
                  { label: "delivery_price", value: "delivery_price" },
                  {
                    label: "delivery_price_limit",
                    value: "delivery_price_limit",
                  },
                  { label: "storepay_id", value: "storepay_id" },
                  { label: "storepay_username", value: "storepay_username" },
                  { label: "storepay_password", value: "storepay_password" },
                  { label: "has_chatbot", value: "has_chatbot" },
                  { label: "hipay_client_id", value: "hipay_client_id" },
                  {
                    label: "hipay_client_secret",
                    value: "hipay_client_secret",
                  },
                  { label: "pixel_id", value: "pixel_id" },
                  {
                    label: "facebook_verification_token",
                    value: "facebook_verification_token",
                  },
                  { label: "pocket_client_id", value: "pocket_client_id" },
                  {
                    label: "pocket_client_secret",
                    value: "pocket_client_secret",
                  },
                  {
                    label: "dark_mode",
                    value: "dark_mode",
                  },
                  {
                    label: "Google Analytics",
                    value: "ga_tracking_id",
                  },
                  {
                    label: "Meta Pixel",
                    value: "pixel_id",
                  },
                ]}
                component={core.components.SelectField}
              />
            </div>
            <div style={{ width: 250 }} className="form-group mr-2">
              <Field
                style={{ width: 250 }}
                required
                disabled={isAddingOption}
                component="input"
                type="text"
                className="form-control"
                placeholder="Утга"
                name="value"
              />
            </div>
            <div className="form-group">
              <button
                disabled={isAddingOption}
                className="btn btn-primary text-bold"
              >
                НЭМЭХ
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="table-responsive">
        <table className="shops-table table table-centered mb-0">
          <thead className="thead-light">
            <tr>
              <th style={{ width: 20 }}>#</th>
              <th>Нэр</th>
              <th style={{ width: 300 }}>Утга</th>
              <th style={{ width: 150 }}>Огноо</th>
              <th className="text-center" style={{ width: 50 }} />
            </tr>
          </thead>
          <tbody>
            {(options || []).map((row, index) => (
              <tr key={`tr_${row.id}`}>
                <td>{index + 1}</td>
                <td className="name">{row.key}</td>
                <td>
                  {typeof row.value === "object"
                    ? JSON.stringify(row.value)
                    : row.value}
                </td>
                <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm")}</td>
                <td className="text-center" style={{ width: 50 }}>
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    href="#"
                    className={"action-icon text-danger"}
                    onClick={(event) => {
                      event.preventDefault();
                      if (window.confirm("Та итгэлтэй байна уу?")) {
                        removeOption(row.key);
                      }
                    }}
                  >
                    <i className="mdi mdi-trash-can-outline" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "addOptionTable",
  enableReinitialize: true,
})(OptionsTable);
