import omit from 'lodash/omit';
import * as types from './actionTypes';
import * as constants from './constants';
export const initialState = {
	errors: {},
};

const postsReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.FETCH_POSTS_BEGIN:
			return {
				...state,
				isFetchingPosts: true,
			};

		case types.FETCH_POSTS_COMPLETE:
			return {
				...state,
				isFetchingPosts: false,
				posts: action.payload.posts,
			};

		case types.FETCH_POSTS_ERROR:
			return {
				...state,
				isFetchingPosts: false,
				errors: {
					...state.errors,
					[constants.FETCH_POSTS]: action.payload.error.message,
				},
			};

		case types.FETCH_POST_DETAIL_BEGIN:
			return {
				...state,
				isFetchingPostDetail: true,
			};

		case types.FETCH_POST_DETAIL_COMPLETE:
			return {
				...state,
				isFetchingPostDetail: false,
				postDetail: action.payload.postDetail,
			};

		case types.FETCH_POST_DETAIL_ERROR:
			return {
				...state,
				isFetchingPostDetail: false,
				errors: {
					...state.errors,
					[constants.FETCH_POST_DETAIL]: action.payload.error.message,
				},
			};

		case types.ADD_POST_BEGIN:
			return {
				...state,
				isAddingPosts: true,
			};

		case types.ADD_POST_COMPLETE:
			return {
				...state,
				isAddingPosts: false,
			};

		case types.ADD_POST_ERROR:
			return {
				...state,
				isAddingPosts: false,
				errors: {
					...state.errors,
					[constants.ADD_POST]: action.payload.error.message,
				},
			};

		case types.UPDATE_POST_BEGIN:
			return {
				...state,
				isUpdatingPosts: true,
			};

		case types.UPDATE_POST_COMPLETE:
			return {
				...state,
				isUpdatingPosts: false,
			};

		case types.UPDATE_POST_ERROR:
			return {
				...state,
				isUpdatingPosts: false,
				errors: {
					...state.errors,
					[constants.UPDATE_POST]: action.payload.error.message,
				},
			};

		case types.REMOVE_POST_BEGIN:
			return {
				...state,
				isRemovingPosts: true,
			};

		case types.REMOVE_POST_COMPLETE:
			return {
				...state,
				isRemovingPosts: false,
			};

		case types.REMOVE_POST_ERROR:
			return {
				...state,
				isRemovingPosts: false,
				errors: {
					...state.errors,
					[constants.REMOVE_POST]: action.payload.error.message,
				},
			};

		case '__PLACEHOLDER__':
			// ignore this.
			return state;

		case types.RESET_ERROR:
			return {
				...state,
				errors: omit(state.errors, action.payload.name),
			};

		case types.SET_FILTER:
			return {
				...state,
				filter: action.payload.filter,
			};

		default:
			return state;
	}
};

export default postsReducer;
