import React, { Fragment, useEffect } from "react";
import core from "core";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import enhancers from "./enhancers";
import Table from "./partials/table";
import Filter from "./partials/filter";

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const Pagination = core.components.Pagination;

export function IssueList({
  isFetching,
  filter,
  setFilter,
  clearFilter,
  currentPage,
  fetchResult,
  remove,
  isRemoving,
  list,
  limit,
  updateQueryString,
  changeStatus,
  isChangingStatus,
}) {
  useEffect(() => list(currentPage, filter), [currentPage, filter]);
  useEffect(() => {
    if (isRemoving === false) {
      list(currentPage, filter);
    }
  }, [isRemoving]);
  useEffect(() => {
    if (isChangingStatus === false) {
      list(currentPage, filter);
    }
  }, [isChangingStatus]);
  return (
    <Fragment>
      <PageTitle title="Хүсэлтүүд" />
      <div className="card">
        <div className="card-body">
          <Filter
            newUrl="/issues/new"
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            onSubmit={(formData) => {
              if (!isEqual(formData, {})) {
                updateQueryString(1);
                const theFilter = pick(formData, ["code"]);

                if (formData.status) {
                  theFilter.status = formData.status.id;
                }

                if (formData.issue_type) {
                  theFilter.issue_type = formData.issue_type.id;
                }

                if (formData.merchant) {
                  theFilter.merchant_id = formData.merchant.id;
                }

                setFilter(theFilter);
              }
            }}
          />

          {isFetching === undefined || isFetching === true ? (
            <Loading />
          ) : (
            <Table
              limit={limit}
              remove={remove}
              isRemoving={isRemoving}
              changeStatus={changeStatus}
              isChangingStatus={isChangingStatus}
              currentPage={currentPage}
              rows={fetchResult.issues}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
const IssueListWithState = enhancers.redux(IssueList);
export default IssueListWithState;
