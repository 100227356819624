import React, { Fragment, useEffect } from "react";
import { Field } from "redux-form";

import core from "core";
import enhancers from "./enhancers";
// import FormGroup from 'core/components/FormGroup';

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function ShopForm({
  isEdit,
  handleSubmit,
  isAdding,
  isUpdating,
  isFetching,
  fetchDetail,
  addError,
  updateError,
  resetErrors,
  goBackList,
  clearForm,
  custom_domain,
  shopType,
  currentPage,
  has_chatbot,
  settlement_status,
  order_sms_enabled,
  fetchCategories,
  shopCategories,
}) {
  useEffect(() => {
    fetchDetail();
    fetchCategories();
  }, []);
  useEffect(() => () => clearForm(), []);

  const onSubmit = useFormSubmit(
    isAdding || isUpdating,
    addError || updateError,
    handleSubmit,
    resetErrors,
    () => goBackList(currentPage, shopType)
  );

  const isSaving = isAdding || isUpdating;

  return (
    <Fragment>
      <PageTitle title={`Дэлгүүр ${isEdit ? "засах" : "нэмэх"}`} />
      <div className="card">
        <div id="shop-update-form" className="card-body">
          {(isEdit && isFetching === undefined) || isFetching === true ? (
            <Loading />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-lg-4">
                  <FormGroup required label="Нэр">
                    <Field
                      required
                      name="name"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Нэр"
                    />
                  </FormGroup>
                </div>
                {/* <div className="col-lg-4">
                  <FormGroup label="Дуусах хугацаа">
                    <Field
                      name="expire_at"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Дуусах хугацаа"
                      dateFormat="yyyy-MM-dd"
                      component={core.components.DateTimePicker}
                    />
                  </FormGroup>
                </div> */}
                <div className="col-lg-4">
                  <FormGroup required label="Домайн /UID/">
                    <Field
                      required
                      name="uid"
                      type="text"
                      component="input"
                      disabled={isSaving || custom_domain}
                      className="form-control"
                      placeholder="Домайн /UID/"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <FormGroup label="Лого">
                    <Field
                      name="logo"
                      disabled={isSaving}
                      component={core.components.FileUpload}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Favicon">
                    <Field
                      name="favicon"
                      disabled={isSaving}
                      component={core.components.FileUpload}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup required label="Утас">
                    <Field
                      required
                      name="phone"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Утас"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <FormGroup label="FB хаяг">
                    <Field
                      name="facebook_url"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="FB хаяг"
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Instragram хаяг">
                    <Field
                      name="instagram_url"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Instragram хаяг"
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Youtube хаяг">
                    <Field
                      name="youtube_url"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Youtube хаяг"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <FormGroup label="И-мэйл хаяг">
                    <Field
                      name="email"
                      type="email"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="И-мэйл хаяг"
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Google map - lat, lng">
                    <Field
                      name="lat"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Уртраг"
                    />
                  </FormGroup>
                </div>
                <div style={{ marginTop: 8 }} className="col-lg-4">
                  <FormGroup label="">
                    <Field
                      name="lng"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Өргөрөг"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <FormGroup label="Хаяг">
                    <Field
                      name="address"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Хаяг"
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Чатботтой эсэх">
                    <Field
                      name="has_chatbot"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Сонгох..."
                      options={[
                        { label: "Идэвхтэй", value: "enabled" },
                        { label: "Идэвхгүй", value: "disabled" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Захиалгын формын төрөл">
                    <Field
                      name="checkout_form_type"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Сонгох..."
                      options={[
                        { label: "Хураангуй", value: "simple" },
                        { label: "Дэлгэрэнгүй", value: "detailed" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-4">
                  <FormGroup label="Захиалгын доод лимит">
                    <Field
                      name="order_min_limit"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Захиалгын доод лимит"
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Захиалгын дээд лимит">
                    <Field
                      name="order_max_limit"
                      type="text"
                      component="input"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Захиалгын дээд лимит"
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Main Banner 1">
                    <Field
                      name="mainBanner1"
                      disabled={isSaving}
                      component={core.components.FileUpload}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <FormGroup label="Main Banner 2">
                    <Field
                      name="mainBanner2"
                      disabled={isSaving}
                      component={core.components.FileUpload}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Main Banner 3">
                    <Field
                      name="mainBanner3"
                      disabled={isSaving}
                      component={core.components.FileUpload}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup label="Борлуулалтын сувгууд">
                    <Field
                      name="sale_channels"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Сонгох..."
                      multi={true}
                      options={[
                        { label: "Supersale", value: "supersale" },
                        { label: "Monpay", value: "monpay" },
                        { label: "Socialpay", value: "socialpay" },
                        { label: "Hipay", value: "hipay" },
                        { label: "Zamch", value: "zamch" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-4">
                  <FormGroup classNames="clearfix" label="Хүргэлтийн компаниуд">
                    <Field
                      name="enabled_delivery_companies"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Сонгох..."
                      multi={true}
                      options={[
                        { label: "OneGo", value: "goDelivery" },
                        { label: "Booklo", value: "booklo" },
                        { label: "delko", value: "delko" },
                        { label: "mongolpost", value: "mongolpost" },
                        { label: "Tu-Delivery", value: "tuDelivery" },
                        { label: "Буухиа Элч", value: "buukhiaElch" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup classNames="clearfix" label="Мастер ангилал">
                    <Field
                      name="category_codes"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Сонгох..."
                      multi={true}
                      options={shopCategories.map((category) => ({
                        label: category.name,
                        value: category.code,
                      }))}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-4">
                  <FormGroup
                    classNames="clearfix"
                    label="Захиалгын SMS / Email"
                  >
                    <Field
                      name="order_sms_enabled"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Сонгох..."
                      options={[
                        { label: "Идэвхтэй", value: "enabled" },
                        { label: "Идэвхгүй", value: "disabled" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                {order_sms_enabled === "enabled" && (
                  <>
                    <div className="col-lg-4">
                      <FormGroup label="Захиалгa үүссэн SMS загвар">
                        <Field
                          name="order_sms_template_1"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Захиалгa үүссэн SMS загвар"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-4">
                      <FormGroup label="Захиалгa баталгаажсан SMS загвар">
                        <Field
                          name="order_sms_template_2"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Захиалгa баталгаажсан SMS загвар"
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
                <div className="col-lg-4">
                  <FormGroup required label="Загвар">
                    <Field
                      required
                      name="theme"
                      disabled={isSaving}
                      placeholder="Загвар сонгох..."
                      options={[
                        { label: "Сошиал", value: "default" },
                        { label: "Минимал", value: "legacy" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>

                <div className="col-lg-4">
                  <FormGroup classNames="clearfix" label="Zochil Checkout">
                    <Field
                      name="settlement_status"
                      disabled={isSaving}
                      className="form-control"
                      placeholder="Сонгох..."
                      options={[
                        { label: "Идэвхтэй", value: "enabled" },
                        { label: "Идэвхгүй", value: "disabled" },
                      ]}
                      component={core.components.SelectField}
                    />
                  </FormGroup>
                </div>
                {settlement_status === "enabled" && (
                  <>
                    <div className="col-lg-4">
                      <FormGroup required label="Үндсэн дансны банк">
                        <Field
                          required
                          name="main_account_bank"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Сонгох..."
                          options={[
                            { label: "Хаан банк", value: "khan" },
                            { label: "Голомт банк", value: "golomt" },
                            { label: "ХХБ", value: "tdb" },
                            { label: "Хас банк", value: "xac" },
                            { label: "Төрийн банк", value: "statebank" },
                            { label: "Капитрон банк", value: "capitron" },
                            { label: "Ариг банк", value: "arig" },
                            {
                              label: "Хөрөнгө оруулалтын банк",
                              value: "nibank",
                            },
                            { label: "Чингис хаан банк", value: "chinggis" },
                            { label: "Богд банк", value: "bogd" },
                            {
                              label: "Тээвэр хөгжлийн банк",
                              value: "transbank",
                            },
                          ]}
                          component={core.components.SelectField}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-4">
                      <FormGroup required label="Үндсэн данс дугаар">
                        <Field
                          required
                          name="main_account_number"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Үндсэн данс дугаар"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-4">
                      <FormGroup required label="Үндсэн дансны эзэмшигч">
                        <Field
                          required
                          name="main_account_holder"
                          type="text"
                          component="input"
                          disabled={isSaving}
                          className="form-control"
                          placeholder="Үндсэн дансны эзэмшигч"
                        />
                      </FormGroup>
                    </div>
                  </>
                )}
              </div>
              {has_chatbot === "enabled" && (
                <>
                  <div className="row">
                    <div className="col-xl-12">
                      <h5 className="page-title">
                        Чатботны тохиргоо / Зурагнууд /
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3">
                      <FormGroup label="Дэлгүүр хэсэх">
                        <Field
                          name="chatbot_product_home_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-3">
                      <FormGroup label="Бүх бараа">
                        <Field
                          name="chatbot_product_all_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-3">
                      <FormGroup label="Хямдрал">
                        <Field
                          name="chatbot_product_sales_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-3">
                      <FormGroup label="Онцлох бүтээгдэхүүн">
                        <Field
                          name="chatbot_product_featured_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3">
                      <FormGroup label="Мэдээ мэдээлэл">
                        <Field
                          name="chatbot_post_home_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-3">
                      <FormGroup label="Холбоо барих / Тусламж">
                        <Field
                          name="chatbot_contact_home_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-3">
                      <FormGroup label="Яг одоо залгах">
                        <Field
                          name="chatbot_contact_call_now_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-3">
                      <FormGroup label="Салбарууд">
                        <Field
                          name="chatbot_contact_branches_now_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3">
                      <FormGroup label="Түгээмэл асуултууд">
                        <Field
                          name="chatbot_help_faq_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-3">
                      <FormGroup label="Бидний тухай">
                        <Field
                          name="chatbot_help_about_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-lg-3">
                      <FormGroup label="Үйлчилгээний нөхцөлүүд">
                        <Field
                          name="chatbot_help_terms_image_url"
                          disabled={isSaving}
                          component={core.components.FileUpload}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-xl-12">
                  <button className="btn btn-danger">Хадгалах</button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}
const ShopFormWithForm = enhancers.form(ShopForm);
const ShopFormWithState = enhancers.redux(ShopFormWithForm);
export default ShopFormWithState;
