import activities from 'app/activities';
import ad_campaigns from 'app/ad/ad-campaigns';
import ad_sellers from 'app/ad/sellers';
import withdrawals from 'app/ad/withdrawals';
import admins from 'app/admins';
import bills from 'app/bills';
import campaigns from 'app/campaigns';
import channels from 'app/channels';
import contracts from 'app/contracts';
import customers from 'app/customers';
import dashboard from 'app/dashboard';
import issues from 'app/issues';
import messages from 'app/messages';
import modules from 'app/modules';
import notes from 'app/notes';
import notifications from 'app/notifications';
import orders from 'app/orders';
import payments from 'app/payments';
import products from 'app/products';
import settlements from 'app/settlements';
import shops from 'app/shops';
import transactions from 'app/transactions';
import users from 'app/users';
import core from 'core';
import posts from 'app/posts';
//import invoices from 'app/invoices'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	[core.constants.NAME]: core.reducer,
	[bills.constants.NAME]: bills.reducer,
	[shops.constants.NAME]: shops.reducer,
	[users.constants.NAME]: users.reducer,
	[orders.constants.NAME]: orders.reducer,
	[admins.constants.NAME]: admins.reducer,
	[issues.constants.NAME]: issues.reducer,
	[messages.constants.NAME]: messages.reducer,
	[products.constants.NAME]: products.reducer,
	[channels.constants.NAME]: channels.reducer,
	[dashboard.constants.NAME]: dashboard.reducer,
	[customers.constants.NAME]: customers.reducer,
	[activities.constants.NAME]: activities.reducer,
	[settlements.constants.NAME]: settlements.reducer,
	[notifications.constants.NAME]: notifications.reducer,
	[transactions.constants.NAME]: transactions.reducer,
	[payments.constants.NAME]: payments.reducer,
	[contracts.constants.NAME]: contracts.reducer,
	[modules.constants.NAME]: modules.reducer,
	[campaigns.constants.NAME]: campaigns.reducer,
	[ad_sellers.constants.NAME]: ad_sellers.reducer,
	[withdrawals.constants.NAME]: withdrawals.reducer,
	[ad_campaigns.constants.NAME]: ad_campaigns.reducer,
	[notes.constants.NAME]: notes.reducer,
	[posts.constants.NAME]: posts.reducer,
	//[invoices.constants.NAME]: invoices.reducer,
};
