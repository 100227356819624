import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';

function Filter({ newUrl }) {
  return (
    <div className="row mb-2">
      {
        !!newUrl &&
        <div className="col-lg-4">
         <Link
            to={newUrl}
            className="btn btn-primary mb-2 mr-2"
          >
            <i className="mdi mdi-plus-circle mr-1" /> Нэмэх
          </Link>
        </div>
      }
    </div>
  );
}

export default reduxForm({
  form: 'postsFilterForm',
  enableReinitialize: true,
})(Filter);
