import { createSelector } from 'reselect';
import * as constants from './constants';

const loadingSelector = function (state, featureName) {
	switch (featureName) {
		case constants.FETCH_POSTS:
			return state.isFetchingPosts;

		case constants.FETCH_POST_DETAIL:
			return state.isFetchingPostDetail;

		case constants.ADD_POST:
			return state.isAddingPosts;

		case constants.UPDATE_POST:
			return state.isUpdatingPosts;

		case constants.REMOVE_POST:
			return state.isRemovingPosts;

		case '__PLACEHOLDER_LOADING__':
			return false;

		default:
			return false;
	}
};

const errorSelector = (state, featureName) => state.errors[featureName] || "";

export const makeErrorSelector = () => {
	return createSelector([errorSelector], (error) => error);
};
export const makeLoadingSelector = () => {
	return createSelector([loadingSelector], (loading) => loading);
};
export const getPosts = createSelector([(state) => state.posts], (posts) => posts);
export const getPostDetail = createSelector(
	[(state) => state.postDetail],
	(postDetail) => postDetail,
);

export const getFilter = createSelector([(state) => state.filter], (filter) => filter);
