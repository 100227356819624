import * as types from './actionTypes';

export const fetchPosts = ({ filter, options }) => ({
	type: types.FETCH_POSTS_BEGIN,
	payload: {
		filter,
		options,
	},
});

export const fetchPostDetail = ({ id }) => ({
	type: types.FETCH_POST_DETAIL_BEGIN,
	payload: {
		id,
	},
});

export const addPost = ({ post }) => ({
	type: types.ADD_POST_BEGIN,
	payload: {
		post,
	},
});

export const updatePost = ({ id, post }) => ({
	type: types.UPDATE_POST_BEGIN,
	payload: {
		id,
		post,
	},
});

export const removePost=({id})=>({
    type:types.REMOVE_POST_BEGIN,
    payload:{
        id
    }
})

export const resetError = ({ name }) => ({
	type: types.RESET_ERROR,
	payload: {
	  name,
	},
  });
  export const setFilter = ({ filter }) => ({
	type: types.SET_FILTER,
	payload: {
	  filter,
	},
  });