import screens from './screens';

export default [
	{
		path: true,
		path: '/posts',
		component: screens.PostList,
	},
	{
		path: true,
		path: '/posts/new',
		component: screens.PostForm,
	},
	{
		path: true,
		path: '/posts/edit/:id',
		component: screens.PostForm,
	},
];
