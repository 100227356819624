import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { formValueSelector } from 'redux-form';

import * as actions from '../../../actions';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';

const error = selectors.makeErrorSelector();
const loading = selectors.makeLoadingSelector();

const formSelector = formValueSelector('postForm');

export const mapStateToProps = (state, { match }) => ({
	isEdit: !!match.params.id,
	blog_type: (formSelector(state, 'blog_type') || {}).id,
	status: (formSelector(state, 'status') || {}).id,
	addError: error(state[constants.NAME], constants.ADD_POST),
	isAdding: loading(state[constants.NAME], constants.ADD_POST),
	updateError: error(state[constants.NAME], constants.UPDATE_POST),
	isUpdating: loading(state[constants.NAME], constants.UPDATE_POST),
	isFetching: loading(state[constants.NAME], constants.FETCH_POST_DETAIL),
	initialValues: !match.params.id
		? {}
		: {
				...selectors.getPostDetail(state[constants.NAME]),
				status:
					(selectors.getPostDetail(state[constants.NAME]) || {}).status === 'enabled'
						? { id: 'enabled', name: 'Идэвхтэй' }
						: { id: 'disabled', name: 'Идэвхгүй' },
				blog_type:
					(selectors.getPostDetail(state[constants.NAME]) || {}).blog_type === 'news'
						? { id: 'news', name: 'Мэдээ' }
						: { id: 'update', name: 'Шинэчлэгдсэн' },
		  },
});
export const mapDispatchToProps = (dispatch, { match: { params } }) => ({
	fetchDetail() {
		if (params.id) {
			dispatch(actions.fetchPostDetail({ id: params.id }));
		}
	},
	onSubmit(post) {
		if (post.blog_type) {
			post.blog_type = post.blog_type.id;
		}
		if (post.status) {
			post.status = post.status.id;
		}
		if (post.id) {
			dispatch(actions.updatePost({ id: post.id, post }));
		} else {
			dispatch(actions.addPost({ post }));
		}
	},
	resetErrors() {
		dispatch(actions.resetError({ name: constants.ADD_POST }));
		dispatch(actions.resetError({ name: constants.UPDATE_POST }));
	},
	clearForm() {},
	goBackList() {
		dispatch(push('/posts'));
	},
});

export default (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);
