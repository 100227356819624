import React, { Fragment, useEffect } from 'react';
import core from 'core';
import enhancers from './enhancers';
import Filter from './partials/filter';
import Table from './partials/table';

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
export function PostList({
	isFetching,
	isRemoving,
	filter,
	setFilter,
	clearFilter,
	currentPage,
	fetchResult,
	fetchPosts,
	remove,
	currentUser,
}) {
	useEffect(() => {
		fetchPosts(currentPage, filter);
	}, [currentPage, filter]);
	useEffect(() => {
		if (isRemoving === false) {
			fetchPosts(currentPage, filter);
		}
	}, [, isRemoving]);
	return (
		<Fragment>
			<PageTitle title='Мэдээ мэдээлэл' />
			<div className='card'>
				<div className='card-body'>
					<Filter
						newUrl='/posts/new'
						filter={filter}
						setFilter={setFilter}
						clearFilter={clearFilter}
					/>

					{isFetching === undefined || isFetching === true ? (
						<Loading />
					) : (
						<Table
							rows={fetchResult.posts}
							remove={remove}
							isRemoving={isRemoving}
							currentUser={currentUser}
						/>
					)}
				</div>
			</div>
		</Fragment>
	);
}
const PostListWithState = enhancers.redux(PostList);
export default PostListWithState;
