import React from "react";
import { Link } from "react-router-dom";
import { haveRole } from "core/helpers";

function MainMenu({ currentUser }) {
  return (
    <div className="shadow-sm topnav">
      <div className="container-fluid">
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
          <div className="collapse navbar-collapse" id="topnav-menu-content">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link
                  to="/"
                  id="topnav-dashboards"
                  className="nav-link dropdown-toggle arrow-none"
                >
                  <i className="mr-1 mdi mdi-monitor" />
                  Эхлэл
                </Link>
              </li>
              {haveRole(currentUser, "shop_view") && (
                <li className="nav-item dropdown">
                  <Link
                    to="/shops"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="mr-1 uil-store" /> Дэлгүүр
                  </Link>
                </li>
              )}
              {haveRole(currentUser, "user_view") && (
                <li className="nav-item dropdown">
                  <Link
                    to="/users"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="mr-1 uil-users-alt" /> Хэрэглэгчид
                  </Link>
                </li>
              )}
              {haveRole(currentUser, "order_view") && (
                <li className="nav-item dropdown">
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    className="nav-link dropdown-toggle arrow-none"
                    id="topnav-pages"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="mr-1 mr-1 uil-calendar-alt" />
                    Гүйлгээ <div className="arrow-down" />
                  </a>
                  <div className="dropdown-menu" aria-labelledby="topnav-pages">
                    {/* <Link to="/channels" className="dropdown-item">
                      Борлуулалтын суваг
                    </Link>
                    <Link to="/messages" className="dropdown-item">
                      Мессежүүд
                    </Link>
                    <Link to="/transactions" className="dropdown-item">
                      Гүйлгээ
                    </Link>
                    <Link to="/payments" className="dropdown-item">
                      Төлбөрийн хүсэлтүүд
                    </Link>
                    */}
                    <Link to="/orders" className="dropdown-item">
                      Захиалга
                    </Link>
                    {currentUser.is_super === true && (
                      <Link to="/invoices" className="dropdown-item">
                        Нэхэмжлэх үүсгэх
                      </Link>
                    )}
                  </div>
                </li>
              )}
              {haveRole(currentUser, "user_view") && (
                <li className="nav-item dropdown">
                  <Link
                    to="/modules"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="mr-1 uil-book-alt" /> Модулууд
                  </Link>
                </li>
              )}
              {haveRole(currentUser, "user_view") && (
                <li className="nav-item dropdown">
                  <Link
                    to="/bills"
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="mr-1 uil-book-alt" /> Биллүүд
                  </Link>
                </li>
              )}
              {["temuult", "batbileg", "admin", "Ted2023"] && (
                <li className="nav-item dropdown">
                  {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a
                    className="nav-link dropdown-toggle arrow-none"
                    id="topnav-pages"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="mr-1 uil-chart-growth" />
                    Sharely <div className="arrow-down" />
                  </a>
                  <div className="dropdown-menu" aria-labelledby="topnav-pages">
                    {/* <Link to="/channels" className="dropdown-item">
                    Борлуулалтын суваг
                  </Link>
                  <Link to="/messages" className="dropdown-item">
                    Мессежүүд
                  </Link> */}
                    <Link to="/ad/campaigns" className="dropdown-item">
                      Сурталчилгаанууд
                    </Link>
                    <Link to="/ad/sellers" className="dropdown-item">
                      Нөлөөлөгчид
                    </Link>
                    <Link to="/ad/withdrawals" className="dropdown-item">
                      Мөнгө татах хүсэлтүүд
                    </Link>
                  </div>
                </li>
              )}
              <li className="nav-item dropdown">
                {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                <a
                  className="nav-link dropdown-toggle arrow-none"
                  id="topnav-pages"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="mr-1 uil-info-circle" />
                  Бусад <div className="arrow-down" />
                </a>
                <div className="dropdown-menu" aria-labelledby="topnav-pages">
                  {/* <Link to="/channels" className="dropdown-item">
                    Борлуулалтын суваг
                  </Link>
                  <Link to="/messages" className="dropdown-item">
                    Мессежүүд
                  </Link> */}
                  <Link to="/issues" className="dropdown-item">
                    Хүсэлтүүд
                  </Link>
                  {currentUser.is_super === true && (
                    <>
                      <Link to="/admins" className="dropdown-item">
                        Админууд
                      </Link>
                      <Link to="/campaigns" className="dropdown-item">
                        Мерчант купон үүсгэх
                      </Link>
                      <Link to="/broadcasts" className="dropdown-item">
                        Зарлал
                      </Link>
                      <Link to="/invoices" className="dropdown-item">
                        Нэхэмжлэх
                      </Link>
                      <Link to="/posts" className="dropdown-item">
                        Мэдээ мэдээлэл
                      </Link>
                    </>
                  )}
                </div>
              </li>
              {/* <li className="nav-item dropdown">
                eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  className="nav-link dropdown-toggle arrow-none"
                  id="topnav-pages"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-boxes mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                  </svg>
                  Тооцоо <div className="arrow-down" />
                </a>
                <div className="dropdown-menu" aria-labelledby="topnav-pages">
                  <>
                    <Link to="/issues" className="dropdown-item">
                      Дэлгүүрүүд
                    </Link>
                    <Link to="/admins" className="dropdown-item">
                      Захиалга
                    </Link>
                    <Link to="/contracts" className="dropdown-item">
                      Гэрээ
                    </Link>
                  </>
                </div>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default MainMenu;
