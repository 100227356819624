import omit from 'lodash/omit';
export default class PostsService {
	constructor(fetchUtil, storage) {
		this.storage = storage;
		this.fetchUtil = fetchUtil;
	}

	async fetchPosts({ filter, options }) {
		const { data: posts } = await this.fetchUtil.sendGet('/blogs/list');

		return { posts };
	}

	async fetchPostDetail({ id }) {
		const { data: post } = await this.fetchUtil.sendGet(`/blogs/detail/${id}`);
		return post;
	}

	async addPost({ post }) {
		await this.fetchUtil.sendPost('/blogs/create', post);
	}

	async updatePost({ id, post }) {
		await this.fetchUtil.sendPost('/blogs/update', { ...post, id });
	}

	async removePost({ id }) {
		await this.fetchUtil.sendPost('/blogs/remove', { id });
	}

	__PLACEHOLDER_METHOD__() {}
}
