import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form';
import core from 'core';
import enhancers from './enhancers';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import DraftEditorField from './enhancers/Editor'; // Import the custom field
import { convertToRaw } from 'draft-js';

const Loading = core.components.Loading;
const PageTitle = core.components.PageTitle;
const FormGroup = core.components.FormGroup;
const { useFormSubmit } = core.hooks;

export function PostForm({
	isEdit,
	handleSubmit,
	isAdding,
	isUpdating,
	isFetching,
	fetchDetail,
	addError,
	updateError,
	resetErrors,
	goBackList,
	clearForm,
}) {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const onEditorStateChange = (newEditorState) => {
		setEditorState(newEditorState);
	};

	useEffect(() => fetchDetail(), []);
	useEffect(() => () => clearForm(), []);
	const onSubmit = useFormSubmit(
		isAdding || isUpdating,
		addError || updateError,
		handleSubmit,
		resetErrors,
		goBackList,
		(values) => {
			// Check if values.body contains editorState and serialize it
			if (values.body && values.body.editorState) {
				const contentState = values.body.editorState.getCurrentContent();
				const rawContent = convertToRaw(contentState); // Convert content to raw JSON format
				values.body = JSON.stringify(rawContent); // Store raw content as JSON string
			}
			handleSubmit(values); // Proceed with form submission
		},
	);
	const isSaving = isAdding || isUpdating;

	return (
		<Fragment>
			<PageTitle title={`Мэдээ ${isEdit ? 'засах' : 'нэмэх'}`} />
			<div className='card'>
				<div className='card-body'>
					{(isEdit && isFetching === undefined) || isFetching === true ? (
						<Loading />
					) : (
						<form onSubmit={onSubmit}>
							<div className='row'>
								<div className='col-xl-4'>
									<FormGroup required label='Гарчиг'>
										<Field
											required
											name='title'
											type='text'
											component='input'
											disabled={isSaving}
											className='form-control'
											placeholder='Гарчиг'
										/>
									</FormGroup>
								</div>
								<div className='col-xl-4'>
									<FormGroup required label='Мэдээний төрөл'>
										<Field
											required
											name='blog_type'
											type='text'
											disabled={isSaving}
											className='form-control'
											placeholder='Бичлэгийн холбоос оруулна уу'
											options={[
												{ label: 'Мэдээ', value: 'news' },
												{ label: 'Шинэчлэгдсэн', value: 'update' },
											]}
											component={core.components.SelectField}
										/>
									</FormGroup>
								</div>

								<div className='col-xl-4'>
									<FormGroup required label='Зураг'>
										<Field
											className='form-control'
											name='image'
											disabled={isSaving}
											component={core.components.FileUpload}
										/>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col-xl-4'>
									<FormGroup required label='Бичлэгийн холбоос'>
										<Field
											required
											name='video_url'
											type='text'
											component='input'
											disabled={isSaving}
											className='form-control'
											placeholder='Бичлэгийн холбоос оруулна уу'
										/>
									</FormGroup>
								</div>
								<div className='col-xl-4'>
									<FormGroup required label='Төлөв'>
										<Field
											required
											name='status'
											disabled={isSaving}
											className='form-control'
											placeholder='Төлөв'
											options={[
												{ label: 'Идэвхтэй', value: 'enabled' },
												{ label: 'Идэвхгүй', value: 'disabled' },
											]}
											component={core.components.SelectField}
										/>
									</FormGroup>
								</div>
							</div>

							<FormGroup required label='Тайлбар'>
								<Field
									required
									name='body'
									type='text'
									component='textarea'
									disabled={isSaving}
									className='form-control'
									placeholder='Тайлбар'
									value={'hello'}
									defaultValue={'hello'}
									cols="60"
                      				rows="10"
								/>
								{/* <Editor
									editorState={editorState}
									wrapperClassName='demo-wrapper'
									editorClassName='demo-editor'
									onEditorStateChange={onEditorStateChange}
									placeholder='Enter your text here...'
								/> */}
							</FormGroup>

							<div className='row'>
								<div className='col-xl-12'>
									<button
										className='btn btn-danger '
										type='submit'
										disabled={isAdding || isUpdating}
									>
										Хадгалах
									</button>
								</div>
							</div>
						</form>
					)}
					{(addError || updateError) && (
						<div className='error-message'>{addError || updateError}</div>
					)}
				</div>
			</div>
		</Fragment>
	);
}

const PostFormWithForm = enhancers.form(PostForm);
const PostFormWithState = enhancers.redux(PostFormWithForm);

export default PostFormWithState;
