export const RESET_ERROR = '@@posts/RESET_ERROR';
export const SET_FILTER = '@@posts/SET_FILTER';
export const FETCH_POSTS_BEGIN = '@@posts/FETCH_POSTS_BEGIN';
export const FETCH_POSTS_COMPLETE = '@@posts/FETCH_POSTS_COMPLETE';
export const FETCH_POSTS_ERROR = '@@posts/FETCH_POSTS_ERROR';
export const FETCH_POST_DETAIL_BEGIN = '@@posts/FETCH_POST_DETAIL_BEGIN';
export const FETCH_POST_DETAIL_COMPLETE = '@@posts/FETCH_POST_DETAIL_COMPLETE';
export const FETCH_POST_DETAIL_ERROR = '@@posts/FETCH_POST_DETAIL_ERROR';
export const ADD_POST_BEGIN = '@@posts/ADD_POST_BEGIN';
export const ADD_POST_COMPLETE = '@@posts/ADD_POST_COMPLETE';
export const ADD_POST_ERROR = '@@posts/ADD_POST_ERROR';
export const UPDATE_POST_BEGIN = '@@posts/UPDATE_POST_BEGIN';
export const UPDATE_POST_COMPLETE = '@@posts/UPDATE_POST_COMPLETE';
export const UPDATE_POST_ERROR = '@@posts/UPDATE_POST_ERROR';
export const REMOVE_POST_BEGIN = '@@posts/REMOVE_POST_BEGIN';
export const REMOVE_POST_COMPLETE = '@@posts/REMOVE_POST_COMPLETE';
export const REMOVE_POST_ERROR = '@@posts/REMOVE_POST_ERROR';
